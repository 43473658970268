import React, { useContext, useEffect, useState } from "react";
import Button from "components/Button";
import Textarea from "components/Textarea";
import "./MessageCentre.css";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/Message/action";
import { actions as loaderAction } from "redux/Loader/action";
import MainControlButton from "components/MainControlButton";
import { UserContext } from "components/contexts/User.context";
import moment from "moment";
import WorkExperience from "components/WorkExperience";

const MessageCentre = ({ playerCentre, player, setPlayerCentre }) => {
  const dispatch = useDispatch();
  const [messageSection, setMessageSection] = useState(false);
  const [workExperience, setWorkExperience] = useState(false);
  const [messageHistory, setMessageHistory] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const { messages } = useSelector((state) => state.messages);
  const { user } = useSelector((state) => state.user);
  const { networkProgressDialog } = useSelector((state) => state.loader);
  const { user: userType } = useContext(UserContext);
  const { players } = useSelector((state) => state.players);

  const getMessage = () => {
    if (!messageHistory) {
      dispatch(loaderAction?.startLoader());
      dispatch(actions?.getMessages(player));
    }
    setMessageHistory(!messageHistory);
  };

  const sendMessage = () => {
    const data = [
      {
        email: player?.email,
        message: message,
        tutorName: user?.name,
      },
    ];

    if (!message) {
      setError("Please enter a message!");
    } else {
      dispatch(actions?.sendMessage(data));
      setMessage("");
      setError("");
    }
  };

  const sendMessagesToAllPlayers = () => {
    let playersCopy = structuredClone(players);
    playersCopy = playersCopy?.map((player_) => {
      return {
        email: player_?.email,
        message: message,
        tutorName: user?.name,
      };
    });

    if (!message) {
      setError("Please enter a message!");
    } else {
      dispatch(actions?.sendMessage(playersCopy));
      setMessage("");
      setError("");
    }
  };

  // const viewCV = () => {
  //   dispatch(cvActions.viewCV({ email: player?.email }));
  // };

  useEffect(() => {
    if (player) {
      setMessageHistory(false);
    }
  }, [player]);

  return (
    <>
      <div id="messageCentre">
        <div className="messageControlButton">
          <MainControlButton
            playerCentre={playerCentre}
            player={player}
            setPlayerCentre={setPlayerCentre}
            setMessageSection={setMessageSection}
            messageSection={messageSection}
            setWorkExperience={setWorkExperience}
            workExperience={workExperience}
          />
        </div>
        {/* <div id="controlButtons">
          <ControlButton
            id="viewCv"
            className={`controlBtn click ${
              playerCentre === "viewPlayer" ? "" : "disable"
            }`}
            onClick={viewCV}
            label="View CV"
          />
          <ControlButton
            id="messagePlayer"
            className={`controlBtn click ${
              playerCentre === "viewPlayer" ? "" : "disable"
            }`}
            label="Message"
            onClick={() => handleClick()}
          />
          <ControlButton
            id="playerConfig"
            className="controlBtn click disable"
            label="Player Config"
          />

          <div className="dropdown">
            <button
              id="controlButtons"
              className="dropbtn controlBtn click"
              onClick={() => {
                setPlayerCentre("courseCenter");
                existingCourseClick();
              }}
            >
              Courses
            </button>
            <div className="dropdown-content">
              <button
                id="controlButtons"
                className="dropdownbtn controlBtn click "
              >
                View Existing
              </button>
              <button
                id="controlButtons"
                className="dropdownbtn controlBtn click "
              >
                Create New
              </button> 
            </div>
          </div>
        </div> */}

        {playerCentre === "viewPlayer" && messageSection && (
          <div id="messageSection" className="">
            <Textarea
              maxlength="200"
              className="messageBox"
              id="message"
              value={message}
              handleChange={(e) => setMessage(e.target.value)}
              placeholder={`
GENERAL OR SPECIFIC MESSAGE
        
E.G.

Hi ${userType}, I’ve looked at your current CV and noticed you’ve not yet added the latest huddle video we looked at last week...Upload it to your Technical / Tactical module under ‘recovering possession’.


Cheers! 

Tutor.
        `}
            />

            <div>
              {error && (
                <span id="error" style={{ width: "100%" }}>
                  {error}
                </span>
              )}
              <Button
                className="buttonClass"
                id="sendMsgBtn"
                handleClick={() => sendMessage()}
                label="Send"
              />
              <Button
                className="buttonClass"
                id="sendMsgBtn"
                handleClick={() => sendMessagesToAllPlayers()}
                label={`Send All ${userType}s`}
                style={{ width: "fit-content", marginRight: "10px" }}
              />
              <span onClick={getMessage} className="historyLink">
                History
              </span>
              {messageHistory && (
                <div className="" id="historyBox">
                  {!networkProgressDialog &&
                  messages &&
                  messages?.length > 0 ? (
                    messages?.map((message) => {
                      return (
                        <div className="historyBox" key={message?.id}>
                          <p className="fw-bold text-green mb-1">
                            {message?.tutor ? message?.tutor : ""}
                          </p>
                          <p>{message?.description}</p>

                          <p
                            className="text-end mt-1"
                            style={{ fontSize: "small" }}>
                            {moment(message?.createdAt).format(
                              "DD/MM/YYYY, h:mm a"
                            )}
                          </p>
                        </div>
                      );
                    })
                  ) : (
                    <p className="historyBox" style={{ textAlign: "center" }}>
                      {networkProgressDialog ? "Loading..." : "No Data Found."}
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        {playerCentre === "viewPlayer" && workExperience && (
          <div id="messageSection">
            <WorkExperience player={player} />
          </div>
        )}
      </div>
    </>
  );
};

export default MessageCentre;
