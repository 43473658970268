import { UserContext } from "components/contexts/User.context";
import Input from "components/Input";
import Label from "components/Label";
import jwt_decode from "jwt-decode";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { INSTITUTE_TYPE_ID_FOOTBALL } from "utils/constants";
import "./PlayerInfo.css";
// const status = Status.map((status) => {OO
//   return { name: status?.name, value: status?.id };
// });

const PlayerInfo = ({ contentEditable, player, setPlayer, setEditCourses }) => {
  const [collapsible, setCollapsible] = useState(true);
  const { myCourses } = useSelector((state) => state.course);
  const { workExperiences } = useSelector((state) => state.workExperience);
  const { clubs } = useSelector((state) => state.club);
  let token = localStorage.getItem("TOKEN");
  const params = useParams();
  // eslint-disable-next-line
  const { user } = useSelector((state) => state.user);
  let decoded;
  if (token) {
    decoded = jwt_decode(token);
  }

  //find clubData
  let clubData =
    params?.id &&
    clubs?.find((club) => {
      return params?.id === club?.id;
    });

  const { user: userType } = useContext(UserContext);

  const [playerStatus, setPlayerStatus] = useState("");
  const handleChange = (event) => {
    setPlayer((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const { playerStatus: playerStatuses } = useSelector(
    (state) => state.players
  );

  const { playerCategory: playerCategories } = useSelector(
    (state) => state.players
  );

  const status =
    playerStatuses &&
    playerStatuses?.length > 0 &&
    playerStatuses?.map((status) => {
      return { name: status.name, value: status.id };
    });

  const category = [
    { name: "None Selected", value: "" }, // Add this line for the "Please Select" option
    ...(playerCategories &&
    Array.isArray(playerCategories) &&
    playerCategories.length > 0
      ? playerCategories.map((category) => ({
          name: category.name,
          value: category.id,
        }))
      : []),
  ];

  const handleChangePlayerStatus = (e) => {
    // const statusObj = playerStatuses.find((status) => {
    //   return status.id === e.value && status;
    // });
    setPlayerStatus(e.value);
    player.status =
      player?.status &&
      player?.status?.length > 0 &&
      player?.status?.map((status) => {
        return status.current === true ? { ...status, current: false } : status;
      });

    const isExist = player?.status?.find((status) => {
      return status.id === e.value;
    });

    if (!isExist) {
      player?.status?.push({
        id: e.value,
        timeStamp: new Date().toISOString(),
        current: true,
      });
    } else {
      player.status =
        player?.status &&
        player?.status?.length > 0 &&
        player?.status?.map((status) => {
          return status.id === e.value
            ? {
                ...status,
                current: true,
                timeStamp: new Date().toISOString(),
              }
            : status;
        });
    }
    setPlayer({
      ...player,
    });
  };

  const handleChangePlayerCategory = (e) => {
    player.playerCategory = e.value;
    setPlayer({
      ...player,
    });
  };

  const onSetCourse = (e) => {
    if (e.checked) {
      setPlayer({
        ...player,
        coursesId: [...player.coursesId, e.value],
      });
    } else {
      const courseIds = player.coursesId.filter((course) => {
        return course !== e.value;
      });
      player.coursesId = courseIds;
      setPlayer({ ...player });
    }
    setEditCourses(true);
  };

  const onSetWorkExperiences = (e) => {
    if (e.checked) {
      setPlayer({
        ...player,
        workExperiencesId: [...player.workExperiencesId, e.value],
      });
    } else {
      const workExpIds = player.workExperiencesId.filter((workExp) => {
        return workExp !== e.value;
      });
      player.workExperiencesId = workExpIds;
      setPlayer({ ...player });
    }
  };

  return (
    <>
      <div id="playerSection" className="">
        <div
          id="playerTitle"
          className={`sectionTitle collapsible ${collapsible ? "active2" : ""}`}
          onClick={() => setCollapsible(!collapsible)}>
          {userType} Centre
        </div>
        <div
          id="dannyRoutesSection"
          className="content"
          style={{ maxHeight: collapsible ? "100%" : "0" }}>
          <div className="createField">
            <p>Name</p>
            {!contentEditable && (
              <p className="fieldText" id="playerName" required>
                {player?.name ? player?.name : ""}
              </p>
            )}
            {contentEditable && (
              <Input
                id="playerName"
                className="fieldText border"
                value={player?.name}
                name="name"
                onChange={(e) => handleChange(e)}
                type="text"
              />
            )}
          </div>
          <div className="createField">
            <p>Email Address</p>
            {(!contentEditable ||
              decoded?.role === "tutor" ||
              decoded?.role === "clubAdmin") && (
              <p className="fieldText" id="studentEmail" required>
                {player?.email ? player?.email : ""}
              </p>
            )}
            {contentEditable && decoded?.role === "admin" && (
              <Input
                id="playerEmail"
                className="fieldText border"
                value={player?.email}
                name="email"
                onChange={(e) => handleChange(e)}
                type="email"
              />
            )}
          </div>
          <div className="createField">
            <p>Date of Birth</p>
            {!contentEditable && (
              <Input
                className="fieldText border0"
                disabled={true}
                required=""
                id="bDate"
                value={player?.date_of_birth ? player?.date_of_birth : ""}
              />
            )}
            {contentEditable && (
              <Input
                id="date_of_birth"
                type="date"
                className="fieldText border"
                name="date_of_birth"
                value={player?.date_of_birth}
                onChange={(e) => handleChange(e)}
              />
            )}
          </div>

          {(params?.id &&
            clubData?.instituteTypeId === INSTITUTE_TYPE_ID_FOOTBALL) ||
          user?.club?.instituteTypeId === INSTITUTE_TYPE_ID_FOOTBALL ? (
            <>
              <div className="createField">
                <p>FA No.</p>
                {!contentEditable && (
                  <p className="fieldText" id="FAno">
                    {player?.FAno ? player?.FAno : ""}
                  </p>
                )}
                {contentEditable && (
                  <Input
                    className="fieldText border"
                    value={player?.FAno}
                    name="FAno"
                    onChange={(e) => handleChange(e)}
                    type="text"
                  />
                )}
              </div>

              <div className="createField">
                <p>PFA No.</p>
                {!contentEditable && (
                  <p className="fieldText" id="PFAno">
                    {player?.PFAno ? player?.PFAno : ""}
                  </p>
                )}
                {contentEditable && (
                  <Input
                    className="fieldText border"
                    value={player?.PFAno}
                    name="PFAno"
                    onChange={(e) => handleChange(e)}
                    type="text"
                  />
                )}
              </div>
              <div className="createField">
                <p>N.I. No.</p>
                {!contentEditable && (
                  <p className="fieldText" id="Nino">
                    {player?.NIno ? player?.NIno : ""}
                  </p>
                )}
                {contentEditable && (
                  <Input
                    className="fieldText border"
                    value={player.NIno}
                    name="NIno"
                    onChange={(e) => handleChange(e)}
                    type="text"
                  />
                )}
              </div>
              <div className="createField">
                <p>Category</p>
                {!contentEditable && (
                  <p className="fieldText" id="playerCategory">
                    {player?.playerCategory
                      ? playerCategories &&
                        playerCategories?.length > 0 &&
                        playerCategories?.map((data) => {
                          return (
                            player?.playerCategory === data?.id && data?.name
                          );
                        })
                      : "None selected"}
                  </p>
                )}
                {contentEditable && (
                  <Dropdown
                    value={player?.playerCategory ? player?.playerCategory : ""}
                    name="playerCategory"
                    options={category}
                    onChange={handleChangePlayerCategory}
                    optionLabel="name"
                    className="w-100 border dropdownfield"
                  />
                )}
              </div>
            </>
          ) : null}

          <div className="createField">
            <p>Status</p>
            {!contentEditable &&
              player?.status &&
              player?.status?.map((status) => {
                return (
                  status?.current === true && (
                    <p className="fieldText" id="playerStatus" key={status?.id}>
                      {playerStatuses &&
                        playerStatuses?.length > 0 &&
                        playerStatuses?.map((data) => {
                          return status?.id === data?.id && data?.name;
                        })}
                    </p>
                  )
                );
              })}
            {contentEditable && (
              <Dropdown
                value={playerStatus}
                name="status"
                options={status}
                // onChange={(e) => setPlayerStatus(e.value)}
                onChange={handleChangePlayerStatus}
                optionLabel="name"
                placeholder={
                  player?.status
                    ? player?.status &&
                      player?.status?.map((status) => {
                        return (
                          status?.current === true &&
                          playerStatuses &&
                          playerStatuses?.length > 0 &&
                          playerStatuses?.map((data) => {
                            return status?.id === data?.id && data?.name;
                          })
                        );
                      })
                    : "Select a Status"
                }
                className="w-100 border dropdownfield"
              />
            )}
          </div>

          {/* <div className="mb-3">
            <Label title="Courses" className="createField" />
            <div className="d-flex flex-wrap gap-4">
              {myCourses?.length > 0 &&
                myCourses?.map((item) => {
                  return (
                    <div className="d-flex gap-2">
                      <label className="container-checkbox">
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            onSetCourse(e, item?.id);
                          }}
                        />
                        <div className="checkmark"></div>
                      </label>
                      <p>{item?.title}</p>
                    </div>
                  );
                })}
            </div>
          </div> */}

          {user?.role !== "tutor" && (
            <>
              <div>
                <Label title="Courses" className="createField" />
                <div className="d-flex flex-wrap gap-3 my-3">
                  {myCourses?.length > 0 &&
                    myCourses?.map((item, i) => {
                      return (
                        <div className="d-flex align-items-center">
                          <Checkbox
                            inputId={`ingredient${i}`}
                            name="course"
                            value={item.id}
                            onChange={onSetCourse}
                            checked={player?.coursesId?.includes(item.id)}
                            disabled={contentEditable ? false : true}
                          />
                          <label
                            htmlFor={`ingredient${i}`}
                            className="ml-2 fs-5">
                            {item.title}
                          </label>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div>
                <Label title="Work Experiences" className="createField" />
                <div className="d-flex flex-wrap gap-3 my-3">
                  {workExperiences?.length > 0 &&
                    workExperiences?.map((item, i) => {
                      return (
                        <div className="d-flex align-items-center">
                          <Checkbox
                            inputId={`workExperience${i}`}
                            name="workExperience"
                            value={item.id}
                            onChange={onSetWorkExperiences}
                            checked={player?.workExperiencesId?.includes(
                              item.id
                            )}
                            disabled={contentEditable ? false : true}
                          />
                          <label
                            htmlFor={`workExperience${i}`}
                            className="ml-2 fs-5">
                            {item.title}
                          </label>
                        </div>
                      );
                    })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PlayerInfo;
